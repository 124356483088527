import * as R from 'ramda'
import * as reselect from 'reselect'
import assert from 'assert'
import { bind } from 'redux-effects'
import { connect } from 'react-redux'

import * as api from '@rushplay/api-client'

import * as Constants from './constants'
import * as cookies from './cookies'
import * as logger from './logger'
import { resolveAcceptedLanguages } from './accepted-languages'

const EMPTY_ARRAY = Object.freeze([])
const EMPTY_OBJECT = Object.freeze({})
const randomNumber = Math.floor(Math.random() * 3) + 1

/**
 * App must be configurable with environment variables without rebuild. Yet all
 * `process.env` in client-side code are replaced with their values during
 * build.
 *
 * Due to that we are not using configuration environment variables in code
 * that is used both on the server and on the client. To propagate server’s
 * environment variables, we put them to `configuration` reducer’s initial state
 * which will be sent to client on every request.
 *
 * This means that all configuration environment variables values must be
 * retrieved from Redux state and not from `process.env` unless it is
 * server-oriented code.
 *
 * This does not apply to build-time variables like `NODE_ENV`.
 */
const INITIAL_STATE = {
  apiUrl: process.env.API_URL,
  subBrand: process.env.SUB_BRAND,
  mahjong: process.env.MAHJONG === 'true',
  features: {},
  gameServerUrl: process.env.GAME_SERVER_URL,
  gqlUrl: process.env.GRAPHQL_URL,
  imgproxyUrl: process.env.IMGPROXY_URL,
  payerUrl: process.env.PAYER_URL,
  version: process.env.RAZZLE_APP_VERSION,
  landingPage: randomNumber,
  vwoEnabled: process.env.VWO_ENABLED === '1',
}

export const CLIENT_TYPE_UPDATED = 'boom/config/CLIENT_TYPE_UPDATED'
export const BRAND_NAME_UPDATED = 'boom/config/BRAND_NAME_UPDATED'
export const COUNTRY_CODE_UPDATED = 'boom/config/COUNTRY_CODE_UPDATED'
export const DEVICE_PIXLE_RATIO_UPDATED =
  'boom/config/DEVICE_PIXLE_RATIO_UPDATED'
export const INIT = 'boom/config/INIT'
export const PREFERRED_LANGUAGES_UPDATED =
  'boom/config/PREFERRED_LANGUAGES_UPDATED'
export const WEBP_SUPPORT_UPDATED = 'boom/config/WEBP_SUPPORT_UPDATED'
export const FEATURE_FLAG_UPDATED = 'FEATURE_FLAG_UPDATED'

function createConnector(spec) {
  const selector = R.applySpec(spec)
  return connect(state => selector(state.configuration))
}

/**
 * Convert environment variable value to boolean
 *
 * @param {(""|"0"|"1")} [input] Environment variable value
 * @returns {boolean}
 */
export function parseFeatureFlagValue(input) {
  if (input === '1') {
    return true
  }

  if (input != null || input !== '' || input !== '0') {
    // eslint-disable-next-line no-console
    console.error(
      'Warning:',
      'Incorrect feature flag value, expected either "1" or "0".',
      'Received:',
      input
    )
  }

  return false
}

export function init(configuration) {
  return {
    type: INIT,
    error: configuration instanceof Error,
    payload: configuration,
  }
}

export function updateClientType(clientType) {
  const supportedClientTypes = R.values(Constants.ClientType)

  if (R.not(R.includes(clientType, supportedClientTypes))) {
    if (process.env.NODE_ENV !== 'production') {
      logger.root.warn({ clientType }, 'unsupported client type detected')
    }

    // Change client type to unknown if unsupported value is given
    return updateClientType(Constants.ClientType.UNKNOWN)
  }

  return {
    type: CLIENT_TYPE_UPDATED,
    payload: clientType,
  }
}

export function updateBrand(brand) {
  return {
    type: BRAND_NAME_UPDATED,
    payload: brand,
  }
}

export function updateCountryCode(countryCode) {
  return {
    type: COUNTRY_CODE_UPDATED,
    payload: countryCode,
  }
}

export function updateDevicePixleRatio(payload) {
  return bind(
    cookies.set('device_pixel_ratio', payload, {
      maxAge: 180 * 24 * 60 * 60 * 1000,
      path: '/',
    }),
    () => ({
      type: DEVICE_PIXLE_RATIO_UPDATED,
      payload,
    })
  )
}

export function updateFeatureFlag(name, input) {
  return {
    type: FEATURE_FLAG_UPDATED,
    payload: {
      name,
      value: parseFeatureFlagValue(input),
    },
  }
}

/**
 * Retrieve and store player’s preferred languages
 * @param {Object} request HTTP request
 */
export function updatePreferredLanguages(request) {
  const languages = resolveAcceptedLanguages(request)
  return {
    type: PREFERRED_LANGUAGES_UPDATED,
    payload: languages,
  }
}

export function updateWebpSupport(boolean) {
  return {
    type: WEBP_SUPPORT_UPDATED,
    payload: boolean,
  }
}

export function fetch() {
  return api.fetchConfig({
    success: res => [
      init({
        apgSealScriptId: res.value.apgSealScriptId,
        availableCurrencies: res.value.availableCurrencies,
        brand: res.value.brand,
        brite: res.value.brite,
        cdnHost: res.value.cdnHost,
        cdnPrefix: res.value.cdnPrefix,
        defaultLanguage: res.value.defaultLanguage,
        environment: res.value.environment,
        expertBlogUrl: res.value.expertBlogUrl,
        footerSections: res.value.footerSections,
        gtmContainerId: res.value.gtmContainerId,
        inventoryStoreEnabled: res.value.inventoryStoreEnabled,
        languages: res.value.supportedLanguages,
        liveChatDepartments: res.value.liveChatDepartments,
        liveChatKey: res.value.liveChatKey,
        loggedOutPhoneVerification: res.value.loggedOutPhoneVerification,
        piq: res.value.piq,
        projs: res.value.projs,
        pusher: res.value.pusher,
        shuftiPro: res.value.shufti,
        supportEmail: res.value.supportEmail,
        xcmSealScriptId: res.value.xcmSealScriptId,
      }),
    ],
    failure: () => init(new Error('errors.http.fetch-failure')),
    version: 1,
  })
}

export function reducer(state = INITIAL_STATE, action) {
  if (action.error) {
    return state
  }

  switch (action.type) {
    case INIT: {
      return R.merge(
        state,
        R.pick(
          [
            'apgSealScriptId',
            'apiUrl',
            'mahjong',
            'availableCurrencies',
            'brand',
            'brite',
            'cdnHost',
            'cdnPrefix',
            'countries',
            'countryCode',
            'defaultLanguage',
            'environment',
            'expertBlogUrl',
            'features',
            'footerSections',
            'gameServerUrl',
            'gtmContainerId',
            'inventoryStoreEnabled',
            'language',
            'languages',
            'liveChatDepartments',
            'liveChatKey',
            'loggedOutPhoneVerification',
            'origin',
            'piq',
            'projs',
            'pusher',
            'shuftiPro',
            'version',
            'vwoEnabled',
            'supportEmail',
            'xcmSealScriptId',
          ],
          action.payload
        )
      )
    }

    case BRAND_NAME_UPDATED: {
      return R.merge(state, { brand: action.payload })
    }

    case CLIENT_TYPE_UPDATED: {
      return R.merge(state, { clientType: action.payload })
    }

    case COUNTRY_CODE_UPDATED: {
      return R.merge(state, { countryCode: action.payload })
    }

    case DEVICE_PIXLE_RATIO_UPDATED: {
      return R.merge(state, { devicePixleRatio: action.payload })
    }

    case FEATURE_FLAG_UPDATED: {
      return {
        ...state,
        features: {
          ...state.features,
          [action.payload.name]: action.payload.value,
        },
      }
    }

    case PREFERRED_LANGUAGES_UPDATED: {
      return R.assoc('preferredLanguages', action.payload, state)
    }

    case WEBP_SUPPORT_UPDATED: {
      return R.merge(state, { hasWebpSupport: action.payload })
    }

    default: {
      return state
    }
  }
}

export function getApiUrl(state) {
  // Allow overriding public API URL in NodeJS
  // We resolve it here and not in initial state to avoid leaking the URL to
  // client-side runtime, which will break the app if happen.
  if (process.env.INTERNAL_API_URL) {
    return process.env.INTERNAL_API_URL
  }

  const apiUrl = R.path(['apiUrl'], state)
  assert(apiUrl != null, 'API URL is unset; check configuration')
  return apiUrl
}

export function getGqlUrl(state) {
  return R.path(['gqlUrl'], state)
}

export function getAvailableCurrencies(state) {
  return R.path(['availableCurrencies'], state)
}

export function getCountryCode(state) {
  return R.path(['countryCode'], state)
}

export function getCurrentLanguage(state) {
  return R.propOr('', 'language', state)
}

export function getBrand(state) {
  return R.pathOr('', ['brand'], state)
}

export function getSubBrand(state) {
  return R.pathOr('', ['subBrand'], state)
}

export function getGameServerUrl(state) {
  const gameServerUrl = R.pathOr('', ['gameServerUrl'], state)
  assert(gameServerUrl != null, 'Gamer URL is unset; check configuration')
  return gameServerUrl
}

export function getEnvironment(state) {
  return R.pathOr('', ['environment'], state)
}

export function getClientType(state) {
  return R.pathOr(Constants.ClientType.UNKNOWN, ['clientType'], state)
}

export function getHasWebpSupport(state) {
  return R.pathOr(false, ['hasWebpSupport'], state)
}

export function getDevicePixleRatio(state) {
  const dpr = R.path(['devicePixleRatio'], state)
  return dpr > 2 ? 2 : dpr
}

export function getOrigin(state) {
  return R.pathOr('', ['origin'], state)
}

export const getLanguages = reselect.createSelector(
  [getEnvironment, R.pathOr(EMPTY_ARRAY, ['languages'])],
  (environment, languages) => {
    if (environment !== 'production') {
      return R.append('xx', languages)
    }

    return languages
  }
)

export function getCdnHost(state) {
  return R.pathOr('', ['cdnHost'], state)
}

export function getCdnPrefix(state) {
  return R.pathOr('', ['cdnPrefix'], state)
}

export const getCdnUrl = reselect.createSelector(
  [getCdnHost, getCdnPrefix],
  (host, prefix) => `${host}/${prefix}`
)

export function getFeatures(state) {
  return state?.configuration?.features || EMPTY_OBJECT
}

export function isInventoryStoreEnabled(state) {
  return R.pathOr(false, ['inventoryStoreEnabled'], state)
}

export function getSupportEmail(state) {
  return R.pathOr('', ['supportEmail'], state)
}

/**
 * Create feature selector. If given feature ID, will create static selector
 * for given feature ID. Will create dynamic selector using `props.feature`
 * otherwise.
 * @param {?string} feature Feature ID
 * @returns {function}
 */
export function createFeatureSelector(feature) {
  if (feature) {
    return reselect.createSelector([getFeatures], features =>
      R.pathOr(false, [feature], features)
    )
  }

  return reselect.createSelector(
    [getFeatures, (state, props) => props.feature],
    (features, feature) => R.pathOr(false, [feature], features)
  )
}

export function getImgproxyUrl(state) {
  return R.path(['imgproxyUrl'], state)
}

export function getPayerUrl(state) {
  const payerUrl = R.path(['payerUrl'], state)
  assert(payerUrl != null, 'Payer URL is unset; check configuration')
  return payerUrl
}

export function getPiq(state) {
  return R.pathOr(EMPTY_OBJECT, ['piq'], state)
}

export const getPiqEncrypterSrc = reselect.createSelector(
  [getPiq],
  ({ host, merchantId }) =>
    host && merchantId ? `${host}/api/viq/jscardencrypter/${merchantId}` : null
)

export function getBrite(state) {
  return R.pathOr(EMPTY_OBJECT, ['brite'], state)
}

export function getProjs(state) {
  return R.pathOr(EMPTY_OBJECT, ['projs'], state)
}

export function getPusher(state) {
  return R.pathOr(EMPTY_OBJECT, ['pusher'], state)
}

export function isIpInternal(state) {
  return R.pathOr(false, ['isIpInternal'], state)
}

export function getDefaultLanguage(state) {
  return R.pathOr('', ['defaultLanguage'], state)
}

export function isMahjongEnabled(state) {
  return R.pathOr(false, ['mahjong'], state)
}

export function isVwoEnabled(state) {
  return R.pathOr(false, ['vwoEnabled'], state)
}

/**
 * Get preferred languages (taken from client)
 * @param {Object} state
 * @returns {?string}
 */
export function getPreferredLanguages(state) {
  return R.pathOr([], ['preferredLanguages'], state)
}

/**
 * Get preferred application language (taken from URL)
 * @param {Object} state
 * @returns {?string}
 */
export const getLanguage = reselect.createSelector(
  [getLanguages, getPreferredLanguages, getDefaultLanguage],
  (languages, preferredLanguages, defaultLanguage) => {
    return R.reduce(
      (language, preferredLanguage) => {
        if (R.includes(preferredLanguage, languages)) {
          return R.reduced(preferredLanguage)
        }

        return language
      },
      defaultLanguage,
      preferredLanguages
    )
  }
)

export const getTranslationsUrl = reselect.createSelector(
  [getCdnUrl, getLanguage],
  (cdnUrl, language) => {
    if (language) {
      return `${cdnUrl}/locales/boom_${R.toLower(language)}.json`
    }
  }
)

function getExpertBlogUrlBase(state) {
  return R.pathOr('', ['expertBlogUrl'], state)
}

export const getExpertBlogUrl = reselect.createSelector(
  [getExpertBlogUrlBase, getLanguage, getDefaultLanguage],
  (baseUrl, language, defaultLanguage) =>
    language !== defaultLanguage ? `${baseUrl}/${language}` : baseUrl
)

export const expertBlogEnabled = reselect.createSelector(
  getExpertBlogUrlBase,
  baseUrl => Boolean(baseUrl)
)

/**
 * Get basename based on language from URL
 * @param {Object} state
 * @returns {string} Basename if language available; `""` otherwise.
 */
export function getBasename(state) {
  const currentLanguage = getCurrentLanguage(state)
  const preferredLanguage = getLanguage(state)

  if (currentLanguage === preferredLanguage) {
    return `/${currentLanguage}`
  }

  return ''
}

export function getVersion(state) {
  return R.pathOr('', ['version'], state)
}

export const getConfig = reselect.createStructuredSelector({
  cdnHost: getCdnHost,
  cdnPrefix: getCdnPrefix,
  clientType: getClientType,
  environment: getEnvironment,
  languages: getLanguages,
  piq: getPiq,
  projs: getProjs,
  pusher: getPusher,
})

/**
 * Higher-order component to inject `brand` into props
 *
 * @param {ReactComponent} component
 * @returns {ReactComponent}
 */
export const withBrand = createConnector({ brand: getBrand })

/**
 * Higher-order component to inject `clientType` into props
 *
 * @param {ReactComponent} component
 * @returns {ReactComponent}
 */
export const withClientType = createConnector({ clientType: getClientType })

export function getLiveChatKey(state) {
  return R.pathOr('', ['liveChatKey'], state)
}

export function getLiveChatDepartments(state) {
  return R.pathOr(EMPTY_OBJECT, ['liveChatDepartments'], state)
}

export const getLiveChatDepartment = reselect.createSelector(
  [getLanguage, getLiveChatDepartments],
  (language, departments) =>
    R.pathOr(EMPTY_OBJECT, [R.toLower(language)], departments)
)

export const getBriteEnabled = reselect.createSelector(
  [getBrite],
  brite => brite.enabled
)

export const getBriteSigninEnabled = reselect.createSelector(
  [getBrite, getPiq],
  (brite, piq) => {
    const localBriteEnabled = brite.enabled && brite.signin_enabled
    const piqBriteEnabled = piq.signin_enabled && piq.kycProvider === 'brite'

    return localBriteEnabled || piqBriteEnabled
  }
)

export function getGtmContainerId(state) {
  return R.path(['gtmContainerId'], state)
}

export function getApgSealScriptId(state) {
  return R.path(['apgSealScriptId'], state)
}

export function getXcmSealScriptId(state) {
  return R.path(['xcmSealScriptId'], state)
}

// "Footer" selectors
export function getPaymentProviderImageUrls(state) {
  return R.pathOr(EMPTY_ARRAY, ['footerSections', 'paymentProviders'], state)
}

export function getGameProviderImageUrls(state) {
  return R.pathOr(EMPTY_ARRAY, ['footerSections', 'gameProviders'], state)
}

export function getSocialMediaLinks(state) {
  return R.pathOr(EMPTY_ARRAY, ['footerSections', 'socialMedia'], state)
}

export function getLoggedOutPhoneVerification(state) {
  return R.pathOr(false, ['loggedOutPhoneVerification'], state)
}

export function getShuftiProEnabled(state) {
  return R.pathOr(false, ['shuftiPro', 'enabled'], state)
}

export const getDistributedTracingOrigins = reselect.createSelector(
  [
    state => getApiUrl(state),
    state => getGqlUrl(state),
    state => getGameServerUrl(state),
  ],
  (...origins) => origins
)

export function isSeonEnabled(state) {
  const features = getFeatures(state)

  if (Object.keys(features).length === 0) {
    return false
  }

  return features?.seon || false
}

export function isRandomLpEnabled(state) {
  const features = getFeatures(state)

  if (Object.keys(features).length === 0) {
    return false
  }

  return features?.random_lp || false
}
