import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as t from '@rushplay/theme'
import * as common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Constants from './constants'
import * as icons from './icons'
import { currencies } from './amount'

function borderColor(props) {
  if (props.focused) {
    return 'secondary'
  }

  if (props.valid) {
    return 'success'
  } else if (props.invalid) {
    return 'danger'
  } else {
    return 'currentColor'
  }
}

function hover(props) {
  if (props.valid || props.invalid || props.disabled || props.focused) {
    return null
  } else {
    return 'inputHover'
  }
}

const Wrapper = styled.label`
  ${props =>
    css({
      alignItems: 'center',
      display: 'flex',
      color: props.focused ? 'secondary' : 'input-text',
      backgroundColor: props.disabled ? 'inputDisabled' : 'input-background',
      border: '2px solid',
      borderColor: borderColor(props),
      borderRadius: 0,
      boxShadow: props.focused ? 0 : null,
      fontSize: 3,
      flexGrow: 1,
      flexShrink: 1,
      maxHeight: '39px',
      overflow: 'hidden',
      '&:hover': {
        borderColor: hover(props),
      },
    })}

  &:disabled {
    cursor: not-allowed;
  }
`

const IconWrapper = styled.div`
  ${props => props.onClick && 'cursor: pointer'};
  ${props =>
    css({
      color: props.color,
    })}
  display: inline-flex;
  justify-content: center;
  width: 2.5em;
`

const InputField = styled('input', {
  shouldForwardProp: common.noneOf(['prependIcon', 'hasStatus']),
})`
  ${props =>
    css({
      width: '100%',
      flexGrow: 1,
      flexShrink: 1,
      fontFamily: 'body',
      fontSize: 3,
      paddingTop: '0.35em',
      paddingBottom: '0.35em',
      paddingLeft: props.prependIcon ? '0em' : '1em',
      paddingRight: props.hasStatus ? '0em' : '2em',
      color: 'input-text',
      backgroundColor: 'transparent',
      border: 0,
      '&::placeholder': {
        color: 'input',
        fontSize: 2,
      },
      '&:focus': { outline: 'none' },
    })}

  &:disabled {
    cursor: not-allowed;
  }

  // hack to change the background-color that's added by autofill
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${t.color('input-background')} inset !important;
    -webkit-text-fill-color: ${t.color('input-text')};
  }
`
export function Input(props) {
  const inputRef = React.useRef(null)
  const [focused, setFocused] = React.useState(props.autoFocus)

  const value = props.value && props.value.toString()
  const valid = props.valid && props.visited && !props.suppressVisualFeedback
  const invalid = !props.valid && props.visited && !props.suppressVisualFeedback

  function handleFocus(event) {
    setFocused(true)

    if (typeof props.onFocusEffect === 'function') {
      props.onFocusEffect(event)
    }
  }

  React.useEffect(() => {
    // Handle focus state for wrapper styles
    if (focused && inputRef.current) {
      inputRef.current.focus()
    }
  }, [focused, inputRef])

  function handleBlur() {
    setFocused(false)
    props.onBlur()
  }

  return (
    <Wrapper
      empty={R.isEmpty(props.value)}
      valid={valid}
      invalid={invalid}
      focused={focused}
      disabled={props.disabled}
      ref={inputRef}
    >
      {props.prependIcon && (
        <IconWrapper color="input">{props.prependIcon}</IconWrapper>
      )}
      {props.currency && (
        <IconWrapper color="input">
          {R.path([props.currency, 'symbol'], currencies)}
        </IconWrapper>
      )}
      <InputField
        autoComplete={props.autoComplete}
        autoCorrect={props.autoCorrect}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        id={props.id}
        inputMode={props.inputMode}
        maxLength={props.maxLength}
        minLength={props.minLength}
        prependIcon={props.prependIcon || props.currency}
        placeholder={props.placeholder}
        type={props.type}
        value={value}
        hasStatus={valid || invalid}
        data-testid={`${props.id}.input-field`}
        onChange={props.onChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      {props.appendIcon && (
        <IconWrapper color="input" onClick={props.onClick}>
          <props.appendIcon />
        </IconWrapper>
      )}
      {valid && (
        <IconWrapper color="success">
          <icons.CheckCircle />
        </IconWrapper>
      )}
      {invalid && (
        <IconWrapper color="danger">
          <icons.Error />
        </IconWrapper>
      )}
    </Wrapper>
  )
}

Input.propTypes = {
  appendIcon: PropTypes.func,
  autoComplete: PropTypes.string,
  autoCorrect: PropTypes.oneOf(['on', 'off']),
  autoFocus: PropTypes.bool,
  // TODO: create amountInput that passes correct icon instead of relying on currency
  currency: PropTypes.oneOf(Constants.CurrencyCodes),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  inputMode: PropTypes.oneOf(['numeric', 'email', 'decimal', 'tel']),
  maxLength: PropTypes.string,
  minLength: PropTypes.string,
  name: PropTypes.string,
  prependIcon: PropTypes.element,
  placeholder: PropTypes.string,
  suppressVisualFeedback: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  visited: PropTypes.bool,
  valid: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocusEffect: PropTypes.func,
}
