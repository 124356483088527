/*

This is a copy of WIP branch from https://github.com/RushPlay/player
Must be replaced with real module when it is ready for usage.

*/

import * as R from 'ramda'
import * as ReduxEffects from 'redux-effects'
import * as reselect from 'reselect'
import md5 from 'md5'
import { useSelector } from 'react-redux'

import * as api from '@rushplay/api-client'
import * as websockets from '@rushplay/websockets'
import * as session from '@rushplay/session'

import * as Constants from './constants'
import * as Cookies from './cookies'

export const ADDRESS_UPDATED = '@rushplay/player/ADDRESS_UPDATED'
export const AFFILIATE_CLICK_ID_UPDATED =
  '@rushplay/player/AFFILIATE_CLICK_ID_UPDATED'
export const AFFILIATE_ID_UPDATED = '@rushplay/player/AFFILIATE_ID_UPDATED'
export const AFFILIATE_TAG_UPDATED = '@rushplay/player/AFFILIATE_TAG_UPDATED'
export const ALLOW_EMAIL_UPDATED = '@rushplay/player/ALLOW_EMAIL_UPDATED'
export const ALLOW_SMS_UPDATED = '@rushplay/player/ALLOW_SMS_UPDATED'
export const AVATAR_UPDATED = '@rushplay/player/AVATAR_UPDATED'
export const BALANCE_TYPE_CHANGE_AKNOWLEDGED =
  '@rushplay/player/BALANCE_TYPE_CHANGE_AKNOWLEDGED'
export const BALANCE_TYPE_CHANGE_PROMPTED =
  '@rushplay/player/BALANCE_TYPE_CHANGE_PROMPTED'
export const BALANCE_UPDATED = '@rushplay/player/BALANCE_UPDATED'
export const BALANCE_TOTALS_UPDATED = 'speedy/player/BALANCE_TOTALS_UPDATED'
export const CLEARED = '@rushplay/player/CLEARED'
export const COUNTRY_CODE_UPDATED = '@rushplay/player/COUNTRY_CODE_UPDATED'
export const TRAFFIC_JUNKY_UPDATED = '@rushplay/player/TRAFFIC_JUNKY_UPDATED'
export const DEPOSIT_COUNT_INCREMENTED =
  '@rushplay/player/DEPOSIT_COUNT_INCREMENTED'
export const DEPOSIT_COUNT_UPDATED = '@rushplay/player/DEPOSIT_COUNT_UPDATED'
export const DEPOSIT_INFORMATION_UPDATED =
  '@rushplay/player/DEPOSIT_INFORMATION_UPDATED'
export const DISPLAY_NAME_UPDATED = '@rushplay/player/DISPLAY_NAME_UPDATED'
export const EMAIL_UPDATED = '@rushplay/player/EMAIL_UPDATED'
export const ENFORCE_DEPOSIT_UPDATED =
  '@rushplay/player/ENFORCE_DEPOSIT_UPDATED'
export const FIRST_NAME_UPDATED = '@rushplay/player/FIRST_NAME_UPDATED'
export const GIFT_CLEARED = '@rushplay/player/GIFT_CLEARED'
export const INIT = '@rushplay/player/INIT'
export const INVENTORY_NOTIFICATIONS_UPDATED =
  '@rushplay/player/INVENTORY_NOTIFICATIONS_UPDATED'
export const LANGUAGE_UPDATED = '@rushplay/player/LANGUAGE_UPDATED'
export const LAST_NAME_UPDATED = '@rushplay/player/LAST_NAME_UPDATED'
export const PROGRESS_UPDATED = '@rushplay/player/PROGRESS_UPDATED'
export const PAYMENT_CONVERTED_AMOUNT_RESET =
  '@rushplay/player/PAYMENT_CONVERTED_AMOUNT_RESET'
export const PAYMENT_CONVERTED_AMOUNT_UPDATED =
  '@rushplay/player/PAYMENT_CONVERTED_AMOUNT_UPDATED'
export const PHONE_NUMBER_UPDATED = '@rushplay/player/PHONE_NUMBER_UPDATED'
export const RECEIPTS_UPDATED = 'speedy/player/RECEIPTS_UPDATED'
export const TOTAL_DEPOSITS_UPDATED = 'speedy/player/TOTAL_DEPOSITS_UPDATED'
export const SESSION_STATS_CLEARED = '@rushplay/player/SESSION_STATS_CLEARED'
export const SESSION_STATS_UPDATED = '@rushplay/player/SESSION_STATS_UPDATED'
export const INVENTORY_STORE_SEEN_UPDATED =
  '@rushplay/player/INVENTORY_STORE_SEEN_UPDATED'
export const SPORTS_SEEN_UPDATED = '@rushplay/player/SPORTS_SEEN_UPDATED'
export const VIP_UPDATED = '@rushplay/player/VIP_UPDATED'
export const WITHDRAW_INFORMATION_UPDATED =
  '@rushplay/player/WITHDRAW_INFORMATION_UPDATED'
export const ENFORCE_PROFILE_REDIRECT =
  '@rushplay/player/ENFORCE_PROFILE_REDIRECT'
export const MAHJONG_SEEN_UPDATED = 'MAHJONG_SEEN_UPDATED'
export const ACCOUNT_DOCUMENTS_SEEN_UPDATED =
  '@rushplay/player/ACCOUNT_DOCUMENTS_SEEN_UPDATED'
export const PHONE_VERIFICATION_REQUIRED_UPDATED =
  '@rushplay/player/PHONE_VERIFICATION_REQUIRED_UPDATED'

const EMPTY_ARRAY = []

/**
 * Stores player info data
 * @memberof rushplay/player
 * @param {Object} response BE response
 * @returns {Object} Redux action
 */
export function playerInfoInit(response) {
  if (response instanceof Error) {
    return {
      type: INIT,
      error: true,
      payload: response,
    }
  }

  return {
    type: INIT,
    // TODO: check if I've missed any data
    payload: {
      abUserId: response.abUserId || null,
      affiliateClickId: response.affiliateClickId || '',
      affiliateId: response.affiliateId || null,
      affiliateSubId: response.affiliateSubId || '',
      affiliateWebmasterId: response.affiliateWebmasterId || '',
      affiliateTag: response.affiliateTag || null,
      allowEmail: response.allowEmail || false,
      allowSMS: response.allowSMS || false,
      avatar: response.avatar || '',
      balanceCents: response.balanceCents || 0,
      betbackDropPoint: response.betbackDropPoint || 0,
      betbackProgress: response.betbackProgress || 0,
      betbackProgressPercentage: response.betbackProgressPercentage || 0,
      birthdate: response.birthdate || '',
      bonusBalanceCents: response.bonusBalanceCents || 0,
      city: response.city || '',
      claimableBalanceCents: response.claimableBalanceCents || 0,
      countryCallingCode: response.countryCallingCode || '',
      countryCode: response.countryCode || '',
      currency: response.currency || '',
      depositAttempts: response.depositAttempts || 0,
      depositNumber: response.depositNumber || 0,
      displayName: response.displayName || '',
      email: response.email || '',
      eurBalanceCents: response.eurBalanceCents || 0,
      firstName: response.firstName || '',
      gift: response.gift || null,
      inventoryNotifications: response.inventoryNotifications || 0,
      inventoryStoreEnabled: response.inventoryStoreEnabled || false,
      isPhoneVerificationRequired:
        response.requiresPhoneValidation === 'restricted',
      language: response.language || '',
      lastDepositCents: response.lastDepositCents || null,
      lastName: response.lastName || '',
      mainBetbackBalanceCents: response.mainBetbackBalanceCents || 0,
      mapsPlayerId: response.mapsPlayerId || '',
      mobile: response.mobile || '',
      moneyBalanceCents: response.moneyBalanceCents || 0,
      nextBetbackBalanceCents: response.nextBetbackBalanceCents || 0,
      personId: response.personId || null,
      realityCheckPeriod: response.realityCheckPeriod || null,
      rubies: response.rubies || 0,
      state: response.state || '',
      street: response.street || '',
      username: response.username || '',
      valueSegment: response.valueSegment || '',
      vip: response.vip || false,
      vipTier: response.vipTier || '',
      withdrawalsDisallowed: response.withdrawalsDisallowed || false,
      zip: response.zip || '',
    },
  }
}

/**
 * Fetches and stores player info response to state
 * @memberof rushplay/player
 * @returns {Object} Redux action
 */
export function fetchPlayerInfo(config = {}) {
  // TODO: replace 'fetchSession' with 'fetchPlayerInfo' when 'fetchPlayerInfo' contains player data
  // return api.fetchPlayerInfo({
  //   version: 2,
  //   success: res => playerInfoInit(res.value.result),
  //   failure: res =>
  //     playerInfoInit(
  //       new Error(`errors.${res.value.message || 'general.unknown'}`)
  //     ),
  // })
  return api.fetchSession({
    version: 1,
    token: config.token,
    success: sessionRes => [
      api.fetchPlayerInfo({
        version: 2,
        success: res => {
          const playerInfo = res.value.result
          const player = sessionRes.value.player

          return [
            playerInfoInit(
              R.mergeAll([
                R.path(['account'], player),
                R.path(['address'], player),
                player,
                playerInfo,
              ])
            ),
            config.success && config.success(),
          ]
        },
        failure: res => {
          // TODO: Avoid doing this request unconditionally. This error happens
          // if player is locked. We don’t want to spam them with error
          // messages in this case.
          if (res.value.message === 'error.accept-terms-and-conditions') {
            return
          }

          if (res.value.message === 'Phone number is not verified') {
            return
          }

          return playerInfoInit(
            new Error(`errors.${res.value.message || 'general.unknown'}`)
          )
        },
      }),
      fetchDepositInformation(),
    ],
    failure: res =>
      playerInfoInit(
        new Error(`errors.${res.value.message || 'general.unknown'}`)
      ),
  })
}

/**
 * Fetches and stores player’s deposit information
 * @returns {Object} Redux action
 */
export function fetchDepositInformation() {
  return api.fetchDepositInformation({
    success: res => updateDepositInformation(res.value),
    failure: res => {
      // TODO: Avoid doing this request unconditionally. This error happens
      // if player is locked. We don’t want to spam them with error
      // messages in this case.
      if (res.value.message === 'error.accept-terms-and-conditions') {
        return
      }

      if (res.value.message === 'Phone number is not verified') {
        return
      }

      const error =
        res instanceof Error
          ? new Error('errors.fetch-failed')
          : new Error(`errors.${res.message || 'general.unknown'}`)
      return updateDepositInformation(error)
    },
    version: 1,
  })
}

/**
 * Fetches and stores player’s withdrawal limits and wagering information
 * @returns {Object} Redux action
 */
export function fetchWithdrawInformation() {
  return api.fetchWithdrawInformation({
    success: res => updateWithdrawInformation(res.value),
    failure: res => {
      const error =
        res instanceof Error
          ? new Error('errors.fetch-failed')
          : new Error(res.value.message)
      return updateWithdrawInformation(error)
    },
    version: 1,
  })
}

/**
 * Fetches and stores player’s session stats
 * @returns {Object} Redux action
 */
export function fetchSessionStats() {
  return api.fetchSessionStats({
    success: res => updateSessionStats(res.value),
    failure: () => {
      return
    },
    version: 2,
  })
}

/**
 * Cleares all the player info data
 * @memberof rushplay/player
 * @returns {Object} Redux action
 */
export function clearPlayerInfo() {
  return {
    type: CLEARED,
  }
}

/**
 * Updates player's address
 * @memberof rushplay/player
 * @param {Object} payload
 * @param {string} [payload.city]
 * @param {string} [payload.street]
 * @param {string} [payload.zip]
 * @returns {Object} Redux action
 */
export function updateAddress(payload) {
  return {
    type: ADDRESS_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

/**
 * Updates player's affiliate click id
 * @memberof rushplay/player
 * @param {string} payload affiliate click id
 * @returns {Object} Redux action
 */
export function updateAffiliateClickId(payload) {
  return {
    type: AFFILIATE_CLICK_ID_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function updateAffiliateId(payload) {
  return {
    type: AFFILIATE_ID_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function updateAffiliateTag(payload) {
  return {
    type: AFFILIATE_TAG_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function updateAllowEmail(payload) {
  return {
    type: ALLOW_EMAIL_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function updateAllowSms(payload) {
  return {
    type: ALLOW_SMS_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function updateAvatar(payload) {
  return {
    type: AVATAR_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function updateBalance(payload) {
  return {
    type: BALANCE_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function acknowledgeBalanceTypeChange() {
  return { type: BALANCE_TYPE_CHANGE_AKNOWLEDGED }
}

export function promptBalanceTypeChange() {
  return { type: BALANCE_TYPE_CHANGE_PROMPTED }
}

export function resetConvertedAmount() {
  return { type: PAYMENT_CONVERTED_AMOUNT_RESET }
}

export function updateConvertedAmount(payload) {
  return {
    type: PAYMENT_CONVERTED_AMOUNT_UPDATED,
    payload,
  }
}

export function updateCountryCode(payload) {
  return {
    type: COUNTRY_CODE_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function updateTrafficJunky(payload) {
  return {
    type: TRAFFIC_JUNKY_UPDATED,
    payload,
  }
}

export function incrementDepositCount() {
  return { type: DEPOSIT_COUNT_INCREMENTED }
}

export function updateDepositCount(payload) {
  return {
    type: DEPOSIT_COUNT_UPDATED,
    payload,
  }
}

export function updateDepositInformation(payload) {
  return {
    type: DEPOSIT_INFORMATION_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function updateDisplayName(payload) {
  return {
    type: DISPLAY_NAME_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function updateEmail(payload) {
  return {
    type: EMAIL_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

/**
 * A flag to enforce opening the user's wallet
 * @param {boolean} payload flag value
 * @return {Object} Redux action
 */
export function updateEnforceDeposit(payload) {
  return {
    type: ENFORCE_DEPOSIT_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function updateEnforceProfileRedirect(payload) {
  return {
    type: ENFORCE_PROFILE_REDIRECT,
    error: payload instanceof Error,
    payload,
  }
}

export function updateFirstName(payload) {
  return {
    type: FIRST_NAME_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function clearGift() {
  return { type: GIFT_CLEARED }
}

export function updateProgress(payload) {
  return {
    type: PROGRESS_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function updateInventoryNotifications(payload) {
  return {
    type: INVENTORY_NOTIFICATIONS_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function updateLanguage(payload) {
  return [
    {
      type: LANGUAGE_UPDATED,
      error: payload instanceof Error,
      payload,
    },
    // We make optimistic store update because we don't really care if the data changes on BE
    api.saveLanguagePreference(payload),
  ]
}

export function updateLastName(payload) {
  return {
    type: LAST_NAME_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function updatePhoneNumber(payload) {
  return {
    type: PHONE_NUMBER_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function updateReceipts(payload) {
  return {
    type: RECEIPTS_UPDATED,
    payload,
  }
}

export function updateBalanceTotals(payload) {
  return {
    type: BALANCE_TOTALS_UPDATED,
    payload,
  }
}

export function updateDepositTotals(payload) {
  return {
    type: TOTAL_DEPOSITS_UPDATED,
    payload,
  }
}

export function updateSessionStats(sessionStats) {
  return {
    type: SESSION_STATS_UPDATED,
    payload: sessionStats,
  }
}

export function clearSessionStats() {
  return {
    type: SESSION_STATS_CLEARED,
  }
}

export function updateVip(payload) {
  return {
    type: VIP_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function updateWithdrawInformation(payload) {
  return {
    type: WITHDRAW_INFORMATION_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function updateInventoryStoreSeen(payload) {
  return ReduxEffects.bind(
    Cookies.set(Constants.CookieKeys.STORE_SEEN, payload, {
      maxAge: 180 * 24 * 60 * 60,
      httpOnly: false,
      path: '/',
    }),
    () => ({
      type: INVENTORY_STORE_SEEN_UPDATED,
      payload,
    })
  )
}

/**
 * Updates if player needs to verify their phone number
 * @memberof rushplay/player
 * @param {boolean} payload
 * @returns {Object} Redux action
 */
export function updatePhoneVerificationRequired(payload) {
  return {
    type: PHONE_VERIFICATION_REQUIRED_UPDATED,
    error: payload instanceof Error,
    payload,
  }
}

export function updateSportsSeen(payload) {
  return ReduxEffects.bind(
    Cookies.set(Constants.CookieKeys.SPORTS_SEEN, payload, {
      maxAge: 180 * 24 * 60 * 60,
      httpOnly: false,
      path: '/',
    }),
    () => ({
      type: SPORTS_SEEN_UPDATED,
      payload,
    })
  )
}

export function updateMahjongSeen(payload) {
  return ReduxEffects.bind(
    Cookies.set(Constants.CookieKeys.MAHJONG_SEEN, payload, {
      maxAge: 180 * 24 * 60 * 60,
      httpOnly: false,
      path: '/',
    }),
    () => ({
      type: MAHJONG_SEEN_UPDATED,
      payload,
    })
  )
}

export function updateAccountItemsSeen(payload) {
  return {
    type: ACCOUNT_DOCUMENTS_SEEN_UPDATED,
    payload,
  }
}

export function reducer(state = {}, action) {
  if (R.path(['error'], action)) {
    return state
  }

  switch (action.type) {
    case INIT: {
      return R.merge(state, action.payload)
    }

    case ADDRESS_UPDATED: {
      const newAddress = R.pickBy(R.complement(R.isNil), {
        city: action.payload.city,
        street: action.payload.street,
        zip: action.payload.zip,
      })
      return R.merge(state, newAddress)
    }

    case AFFILIATE_CLICK_ID_UPDATED: {
      return R.unless(
        R.pipe(getAffiliateClickId, Boolean),
        R.assoc('affiliateClickId', action.payload)
      )(state)
    }

    // TODO: Apply the same logic as for AFFILIATE_CLICK_ID_UPDATED?
    case AFFILIATE_ID_UPDATED: {
      return R.assoc('affiliateId', action.payload, state)
    }

    // TODO: Apply the same logic as for AFFILIATE_CLICK_ID_UPDATED?
    case AFFILIATE_TAG_UPDATED: {
      return R.assoc('affiliateTag', action.payload, state)
    }

    case ALLOW_EMAIL_UPDATED: {
      return R.assoc('allowEmail', action.payload, state)
    }

    case ALLOW_SMS_UPDATED: {
      return R.assoc('allowSMS', action.payload, state)
    }

    case AVATAR_UPDATED: {
      return R.assoc('avatar', action.payload, state)
    }

    case websockets.REGION_PROGRESS_CHANGED:
    case PROGRESS_UPDATED: {
      const newProgress = R.pick(
        [
          'claimableBalanceCents',
          'betbackDropPoint',
          'betbackProgress',
          'betbackProgressPercentage',
          'mainBetbackBalanceCents',
          'nextBetbackBalanceCents',
        ],
        action.payload
      )

      return R.merge(state, newProgress)
    }

    // TODO: Remove when websockets dispatch `player/BALANCE_UPDATED`
    case websockets.BALANCE_CHANGED:
    case BALANCE_UPDATED: {
      if (R.gte(R.path(['lastFetched'], state), action.payload.timestamp)) {
        return state
      }

      const newBalance = R.pickBy(R.complement(R.isNil), {
        currency: action.payload.currency,
        rubies: action.payload.rubies,
        bonusBalanceCents: action.payload.bonusBalanceCents,
        moneyBalanceCents: action.payload.moneyBalanceCents,
        lastFetched: action.payload.timestamp,
      })

      return R.merge(state, newBalance)
    }

    case BALANCE_TOTALS_UPDATED:
      return R.merge(state, {
        balanceTotals: action.payload,
      })

    case BALANCE_TYPE_CHANGE_AKNOWLEDGED: {
      return R.dissoc('showBalanceTypeChangeMessage', state)
    }

    case BALANCE_TYPE_CHANGE_PROMPTED: {
      return R.assoc('showBalanceTypeChangeMessage', true, state)
    }

    case COUNTRY_CODE_UPDATED: {
      return R.assoc('countryCode', action.payload, state)
    }

    case TRAFFIC_JUNKY_UPDATED: {
      return R.assoc('trafficJunky', action.payload, state)
    }

    case DEPOSIT_COUNT_INCREMENTED: {
      return R.assoc('depositNumber', R.inc(state.depositNumber), state)
    }

    case DEPOSIT_COUNT_UPDATED: {
      return R.assoc('depositNumber', action.payload, state)
    }

    case DEPOSIT_INFORMATION_UPDATED:
      return R.assoc('depositInformation', action.payload, state)

    case DISPLAY_NAME_UPDATED: {
      return R.assoc('displayName', action.payload, state)
    }

    case EMAIL_UPDATED: {
      return R.assoc('email', action.payload, state)
    }

    case ENFORCE_DEPOSIT_UPDATED: {
      return R.assoc('enforceDeposit', action.payload, state)
    }

    case ENFORCE_PROFILE_REDIRECT: {
      return R.assoc('enforceProfileRedirect', action.payload, state)
    }

    case FIRST_NAME_UPDATED: {
      return R.assoc('firstName', action.payload, state)
    }

    case GIFT_CLEARED: {
      return R.assoc('gift', null, state)
    }

    case INVENTORY_NOTIFICATIONS_UPDATED: {
      return R.assoc('inventoryNotifications', action.payload, state)
    }

    case LANGUAGE_UPDATED: {
      return R.assoc('language', action.payload, state)
    }

    case LAST_NAME_UPDATED: {
      return R.assoc('lastName', action.payload, state)
    }

    case PAYMENT_CONVERTED_AMOUNT_RESET: {
      return R.assoc('convertedAmount', null, state)
    }

    case PAYMENT_CONVERTED_AMOUNT_UPDATED: {
      return R.assoc('convertedAmount', action.payload)(state)
    }

    case PHONE_NUMBER_UPDATED: {
      const newPhoneNumber = R.pickBy(R.complement(R.isNil), {
        countryCallingCode: action.payload.countryCallingCode,
        mobile: action.payload.mobile,
      })

      return R.merge(state, newPhoneNumber)
    }

    case RECEIPTS_UPDATED: {
      return R.pipe(
        R.set(R.lensPath(['currentPage']), action.payload.meta.page),
        R.set(R.lensPath(['totalPages']), action.payload.meta.total_pages),
        R.set(R.lensPath(['receipts']), action.payload.receipts)
      )(state)
    }

    case SESSION_STATS_UPDATED:
      return R.assoc('sessionStats', action.payload, state)

    case SESSION_STATS_CLEARED:
      return R.dissoc('sessionStats', state)

    case TOTAL_DEPOSITS_UPDATED:
      return R.merge(state, {
        totalDepositCents: action.payload,
      })

    case VIP_UPDATED: {
      return R.assoc('vip', action.payload, state)
    }

    case WITHDRAW_INFORMATION_UPDATED:
      return R.assoc('withdrawInformation', action.payload, state)

    case INVENTORY_STORE_SEEN_UPDATED:
      return R.assoc('inventoryStoreSeen', action.payload, state)

    case SPORTS_SEEN_UPDATED:
      return R.assoc('sportsSeen', action.payload, state)

    case MAHJONG_SEEN_UPDATED:
      return R.assoc('mahjongSeen', action.payload, state)

    case websockets.SHUFTI_PRO_ADDITIONAL_DOCUMENT_STATUS_CHAGED:
      return R.assoc('accountDocumentsSeen', false, state)

    case ACCOUNT_DOCUMENTS_SEEN_UPDATED:
      return R.assoc('accountDocumentsSeen', action.payload, state)

    case PHONE_VERIFICATION_REQUIRED_UPDATED:
      return R.assoc('isPhoneVerificationRequired', action.payload, state)

    case websockets.SESSION_EXPIRED:
    case session.SESSION_UPDATED:
    case CLEARED:
      return {}

    default:
      return state
  }
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getAffiliateClickId(state) {
  return R.path(['affiliateClickId'], state)
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getAffiliateSubId(state) {
  return R.path(['affiliateSubId'], state)
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getAffiliateWebmasterId(state) {
  return R.path(['affiliateWebmasterId'], state)
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getAbUserId(state) {
  return R.path(['abUserId'], state)
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getAffiliateId(state) {
  return R.path(['affiliateId'], state)
}

/**
 * Needed only to generate a btag for google analytics
 * @private
 * @param {Object} state Player state
 * @returns {?string}
 */
function getAffiliateTag(state) {
  return R.path(['affiliateTag'], state)
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getBirthdate(state) {
  return R.path(['birthdate'], state)
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getBtag(state) {
  const affiliateId = getAffiliateId(state)
  const affiliateTag = getAffiliateTag(state)

  if (!affiliateId || !affiliateTag) {
    return null
  }

  return `${affiliateId}_${affiliateTag}`
}

/**
 * @param {Object} state Player state
 * @returns {boolean}
 */
export function isEmailAllowed(state) {
  return R.pathOr(false, ['allowEmail'], state)
}

/**
 * @param {Object} state Player state
 * @returns {boolean}
 */
export function isSMSAllowed(state) {
  return R.pathOr(false, ['allowSMS'], state)
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getAvatar(state) {
  return R.path(['avatar'], state)
}

export function getConvertedAmount(state) {
  return R.pathOr(0, ['convertedAmount'], state)
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getCountryCode(state) {
  return R.path(['countryCode'], state)
}

export function getTrafficJunky(state) {
  return R.path(['trafficJunky'], state)
}

/**
 * Get deposit count
 */
export function getDepositCount(state) {
  return R.pathOr(null, ['depositNumber'], state)
}

/**
 * Get initial deposit value
 */
export function getInitialDeposit(state) {
  return R.path(['depositInformation', 'initialDeposit'], state)
}

/**
 * Get last deposit amount in EUR
 * @returns {number}
 */
export function getLastDepositAmount(state) {
  return R.pathOr(null, ['lastDepositCents'], state)
}

export function getDepositLimits(state) {
  return R.pathOr([], ['depositInformation', 'depositLimits'], state)
}

export const getDepositLimitRemainder = reselect.createSelector(
  [getDepositLimits],
  depositLimits =>
    R.reduce(R.min, Infinity, R.pluck('leftToDepositCents', depositLimits))
)

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getDisplayName(state) {
  return R.path(['displayName'], state)
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getEmail(state) {
  return R.path(['email'], state)
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getFirstName(state) {
  return R.path(['firstName'], state)
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getGiftAmount(player) {
  return R.path(['gift', 'bonusCents'], player)
}

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getInventoryNotificationsCount(state) {
  return R.path(['inventoryNotifications'], state)
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getLanguage(state) {
  return R.path(['language'], state)
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getLastName(state) {
  return R.path(['lastName'], state)
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getPersonId(state) {
  return R.pathOr('', ['personId'], state)
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getSsn(state) {
  return R.slice(2, Infinity, getPersonId(state))
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getUsername(state) {
  return R.path(['username'], state)
}

export function getMapsPlayerId(state) {
  return state?.mapsPlayerId || ''
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getPlayerId(state) {
  const username = getUsername(state)
  return typeof username === 'string' ? md5(username) : null
}

/**
 * Get player session stats
 */
export function getSessionStats(state) {
  return R.pathOr({}, ['sessionStats'], state)
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getValueSegment(state) {
  return R.path(['valueSegment'], state)
}

/**
 * @param {Object} state Player state
 * @returns {?string}
 */
export function getVipLevel(state) {
  return R.path(['vipTier'], state)
}

/**
 * @param {Object} state Player state
 * @returns {boolean}
 */
export function getVip(state) {
  return R.path(['vip'], state)
}

/**
 * @param {Object} state Player state
 * @returns {boolean}
 */
export function isWithdrawalsDisallowed(state) {
  return R.pathOr(false, ['withdrawalsDisallowed'], state)
}

export const getWithdrawalInformation = reselect.createSelector(
  [R.pathOr({}, ['withdrawInformation'])],
  state => ({
    bonusBalanceCents: state.bonusBalanceCents,
    moneyBalanceCents: state.moneyBalanceCents,
    sportWagerBalanceCents: state.sportWagerBalanceCents,
    wagerBalanceCents: state.wagerBalanceCents,
  })
)

export const getWithdrawableBalanceCents = reselect.createSelector(
  [getWithdrawalInformation],
  info => info.moneyBalanceCents
)

/**
 * @typedef {Object} WithdrawLimitsResult
 * @property {string} interval wd limit interval day || week || month
 * @property {number} limitCents wd limit in cents
 * @property {number} leftToWithdrawCents remaining amount of cents to wd
 */

/**
 * @param {Object} state Player state
 * @returns {Array.<WithdrawLimitsResult>}
 */
export function getWithdrawLimits(state) {
  return R.pathOr([], ['withdrawInformation', 'withdrawLimits'], state)
}

/**
 * ADDRESS PROPS
 */

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getCity(state) {
  return R.path(['city'], state)
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getStreet(state) {
  return R.path(['street'], state)
}

/**
 * @param {Object} state Player state (polity)
 * @returns {string}
 */
export function getState(state) {
  return R.path(['state'], state)
}

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getZip(state) {
  return R.path(['zip'], state)
}

/**
 * BALANCE PROPS
 */

/**
 * @param {Object} state Player state
 * @returns {string}
 */
export function getCurrency(state) {
  // TODO: Determine fallback currency in proper way.
  return R.pathOr('EUR', ['currency'], state)
}

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getBalanceCents(state) {
  return getBonusBalanceCents(state) + getMoneyBalanceCents(state)
}

export function getEurBalanceCents(state) {
  return R.path(['eurBalanceCents'], state)
}

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getBonusBalanceCents(state) {
  return R.path(['bonusBalanceCents'], state)
}

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getMoneyBalanceCents(state) {
  return R.path(['moneyBalanceCents'], state)
}

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getRubies(state) {
  return R.path(['rubies'], state)
}

/**
 * PHONE NUMBER PROPS
 */

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getCountryCallingCode(state) {
  return R.path(['countryCallingCode'], state)
}

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getMobile(state) {
  return R.path(['mobile'], state)
}

export function getPhoneNumber(state) {
  const countryCode = getCountryCallingCode(state)
  const phoneNumber = getMobile(state)
  return (
    countryCode &&
    phoneNumber &&
    `${countryCode}${R.replace(/^0+/, '', phoneNumber)}`
  )
}

export function getCardholderName(state) {
  return `${getFirstName(state)} ${getLastName(state)}`
}

export function useCurrency() {
  return useSelector(state => getCurrency(state.player))
}

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getBetBackDropPoint(state) {
  return R.path(['betbackDropPoint'], state)
}

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getBetBackProgress(state) {
  return R.path(['betbackProgress'], state)
}

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getBetBackProgressPercentage(state) {
  return R.path(['betbackProgressPercentage'], state)
}

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getMainBetBackBalanceCents(state) {
  return R.path(['mainBetbackBalanceCents'], state)
}

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getNextBetBackBalanceCents(state) {
  return R.path(['nextBetbackBalanceCents'], state)
}

/**
 * @param {Object} state Player state
 * @returns {number}
 */
export function getClaimableBalanceCents(state) {
  return R.path(['claimableBalanceCents'], state)
}

export function getReceipts(state) {
  return R.pathOr(EMPTY_ARRAY, ['receipts'], state)
}

export function getReceiptById(state, transactionId) {
  return R.find(R.propEq('transactionId', transactionId), state.receipts)
}

export function getTotalReceiptPages(state) {
  return state.totalPages
}

export function getCurrentReceiptPage(state) {
  return state.currentPage
}

export function getTotalDepositsCents(state) {
  return R.or(
    R.path(['totalDepositCents', 'result', 'total_deposit_cents'], state),
    R.path(['balanceTotals', 'depositsCents'], state)
  )
}

export function getTotalWithdrawsCents(state) {
  return R.path(['balanceTotals', 'withdrawsCents'], state)
}

export function getRealityCheckPeriod(state) {
  return R.path(['realityCheckPeriod'], state)
}

export function getEnforceDeposit(state) {
  return R.path(['enforceDeposit'], state)
}

export function getEnforceProfileRedirect(state) {
  return R.path(['enforceProfileRedirect'], state)
}

export function isInventoryStoreEnabled(state) {
  return R.path(['inventoryStoreEnabled'], state)
}

export function isPhoneVerificationRequired(state) {
  return R.path(['isPhoneVerificationRequired'], state)
}

export function hasSeenInventoryStore(state) {
  return R.pathOr(false, ['inventoryStoreSeen'], state)
}

export function hasSeenSports(state) {
  return R.pathOr(false, ['sportsSeen'], state)
}

export function hasSeenMahjong(state) {
  return R.pathOr(false, ['mahjongSeen'], state)
}

export function hasSeenAllAccountDocuments(state) {
  return R.pathOr(true, ['accountDocumentsSeen'], state)
}

export function getDepositAttempts(state) {
  return R.pathOr(0, ['depositAttempts'], state)
}
